<template>
  <CommonDialog
    v-model="showCancelSubDialog"
    :dialog-style="dialogContentStyle"
    popper-class="cancel-sub_dialog"
    @close="cancelDialog"
  >
    <div class="first-checkbox-list">
      <div class="first-checkbox-title">{{ useTranslateI18n(`help.moreDialog.sorry`) }}</div>
      <div class="first-checkbox-title2">{{ useTranslateI18n(`help.moreDialog.cancel`) }}</div>
      <CommonForm
        ref="formRef"
        form-label-position="top"
        :form-inline="false"
        :form-input-list="formInputList"
        :form-model="formModel"
      />
      <div class="btn-list">
        <div class="btn">
          <CommonButton @btn-click="cancelDialog">
            <CommonAnimationScrollText> {{ useTranslateI18n(`help.moreDialog.can`) }} </CommonAnimationScrollText>
          </CommonButton>
        </div>
        <div class="btn">
          <CommonButton :class="[formModel.checkbox.length ? 'selected' : '']" @btn-click="continueClick">
            <CommonAnimationScrollText> {{ useTranslateI18n(`help.moreDialog.continue`) }} </CommonAnimationScrollText>
          </CommonButton>
        </div>
      </div>
    </div>
  </CommonDialog>
</template>

<script setup lang="tsx">
import { IFormPropsInputList } from "@/types/components/common/form"

interface IProps {
  cancel: (...args: any[]) => any
  submit: (options: { reason_id: any[]; reason_content: string }) => any
}

const props = withDefaults(defineProps<IProps>(), {
  cancel() {
    return null
  },
  submit() {
    return null
  }
})

function useCheckForm() {
  // 取消订阅弹窗
  let showCancelSubDialog = ref(true)

  let cancelBtnRef = ref()

  let dialogContentStyle = reactive({
    width: 438,
    padding: "40px",
    backgroundColor: "#fff"
  })

  let formModel = reactive({
    checkbox: [],
    text: ""
  })

  let extraCheckboxId = null

  let formInputList = ref<IFormPropsInputList<typeof formModel>>([
    {
      model: "checkbox",
      type: "checkbox",
      options: []
    },
    {
      showItem: false,
      model: "text",
      label: useTranslateI18n(`help.dialogForm.comment`),
      clearable: true,
      type: "textarea",
      autosize: true,
      resize: "none",
      placeholder: useTranslateI18n(`help.dialogForm.placeholder`)
    }
  ])

  watch(
    () => formModel.checkbox,
    (newValue) => {
      formInputList.value[1].showItem = newValue.includes(extraCheckboxId)
    }
  )

  getEnumList(36).then((data) => {
    formInputList.value[0].options = data.map((item) => {
      if (item.sub_type === 2) {
        extraCheckboxId = item.id
      }
      return {
        label: item.name,
        value: item.id
      }
    })
  })

  const cancelDialog = () => {
    showCancelSubDialog.value = false
    props.cancel && props.cancel()
  }

  // 继续点击
  const continueClick = () => {
    if (formModel.checkbox.length) {
      showCancelSubDialog.value = false
      props.submit &&
        props.submit({
          reason_id: formModel.checkbox,
          reason_content: formModel.text
        })
    }
  }

  return {
    formModel,
    formInputList,
    showCancelSubDialog,
    dialogContentStyle,
    cancelBtnRef,
    cancelDialog,
    continueClick
  }
}

let { formModel, formInputList, showCancelSubDialog, dialogContentStyle, cancelDialog, continueClick } = useCheckForm()
</script>

<style lang="scss" scoped>
.btn-list {
  margin-top: 40px;
  display: flex;
  .btn {
    flex: 1;
    &:nth-child(1) {
      margin-right: 8px;
    }
    &:nth-child(2) {
      cursor: not-allowed;
      :deep(.common-button) {
        pointer-events: none;
      }
    }
    :deep(.common-button) {
      height: 42px !important;
      background-color: rgba(0, 0, 0, 0.05) !important;
      font-weight: 510 !important;
      font-size: 14px !important;
      line-height: 17px !important;
      color: rgba(0, 0, 0, 0.85) !important;
      transition: all 0.3s;
      &.selected {
        background-color: #ffe050 !important;
        pointer-events: unset;
      }
    }
  }
}
.first-checkbox-list {
  .first-checkbox-title {
    @include fontSemibold;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 32px;
  }
  .first-checkbox-title2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  :deep(.pix-common-search) {
    margin-top: 16px;
    .el-form {
      .el-form-item {
        .el-form-item__content {
          .el-textarea__inner {
            padding: 12px;
            height: 114px !important;
          }
        }
      }
    }
    .el-form-item__label {
      color: rgba(0, 0, 0, 0.55);
    }
    .el-checkbox-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      .el-checkbox {
        height: unset;
        display: flex;
        align-items: flex-start;
        margin-bottom: 12px;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
        .el-checkbox__input {
          margin-top: 1px;
          .el-checkbox__inner {
            width: 16px;
            height: 16px;
            border-radius: 2px;
            &::after {
              top: 2px;
              left: 5px;
            }
            &:hover {
              // border-color: $bg-color;
            }
          }
        }
        .el-checkbox__input.is-checked .el-checkbox__inner {
          // background-color: $bg-color;
          // border-color: $bg-color;
        }
        .el-checkbox__input.is-focus .el-checkbox__inner {
          // border-color: $bg-color;
        }
        .el-checkbox__label {
          white-space: pre-wrap;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.55);
          padding-left: 8px;
          font-size: 14px;
          line-height: 20px;
        }
        &:nth-child(2) {
          align-items: flex-start;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.welcome-page-dialog {
  &.cancel-sub_dialog {
    @include device-max-width-600 {
      .content {
        width: 80%;
        .content-d {
          width: 100% !important;
        }
      }
    }

    @include device-max-width-375 {
      .content {
        width: 75%;
        .content-d {
          padding: 20px !important;
        }
        .close-icon {
          right: -32px;
        }
        .first-checkbox-list {
          .btn-list {
            flex-direction: column;
            .btn:nth-child(1) {
              margin-right: 0;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}
</style>
