<template>
  <div class="downgrade-tip-wrapper">
    <div class="t1">{{ title }}</div>
    <div class="desc-list">
      <div v-for="(text, i) in descList" :key="i" class="desc-list-item">
        <span class="desc-list-item-text">
          {{ text }}
        </span>
      </div>
    </div>
    <div class="control-btn">
      <CommonButton :button-style="cancelStyle" @btn-click="cancelClick">
        <CommonAnimationScrollText>{{
          useTranslateI18n(`profile.billingUsage.sub.cancelDialog.btn[0]`)
        }}</CommonAnimationScrollText>
      </CommonButton>
      <CommonButton ref="btnRef" :button-style="submitStyle" @btn-click="submitClick">
        <CommonAnimationScrollText>{{ useTranslateI18n(`payment.comboSub.t94`) }}</CommonAnimationScrollText>
      </CommonButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import variables from "@/assets/scss/responsiveExport.module.scss"

interface IProps {
  title: string
  descList: string[]
}

const props = withDefaults(defineProps<IProps>(), {
  title() {
    return ""
  },
  descList() {
    return []
  }
})

const emits = defineEmits(["cancelClick", "submitClick"])

const cancelStyle = reactive({
  width: "100%",
  height: "42px",
  borderRadius: "60px",
  fontSize: "14px",
  color: "rgba(0, 0, 0, 0.85)",
  backgroundColor: variables.bgColor
})

const submitStyle = reactive({
  width: "100%",
  height: "42px",
  borderRadius: "60px",
  fontSize: "14px",
  color: "rgba(0, 0, 0, 0.85)",
  backgroundColor: "rgba(0, 0, 0, 0.05)"
})

const cancelClick = () => {
  emits("cancelClick")
}

const submitClick = () => {
  emits("submitClick")
}
</script>

<style scoped lang="scss">
.downgrade-tip-wrapper {
  display: flex;
  flex-direction: column;
  line-height: normal;
  color: #000;
  .t1 {
    font-size: 24px;
    @include fontSemibold;
  }
  .desc-list {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.55);
    line-height: 22px;
  }
  .control-btn {
    display: flex;
    margin-top: 40px;
    .common-button {
      flex: 1;
      &:nth-last-child(1) {
        margin-left: 8px;
      }
    }
  }
}
</style>
